.home-container10 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-container11 {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  background-color: var(--dl-color-theme-neutral-light);
}
.home-navbar-interactive {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.home-image1 {
  height: 3rem;
}
.home-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.home-links1 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.home-link11 {
  text-decoration: none;
}
.home-link21 {
  text-decoration: none;
}
.home-link31 {
  text-decoration: none;
}
.home-link41 {
  text-decoration: none;
}
.home-burger-menu {
  display: none;
}
.home-icon10 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-theme-neutral-light);
}
.home-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-logo {
  height: 3rem;
}
.home-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon12 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-links2 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.home-icon-group {
  gap: var(--dl-space-space-twounits);
  display: flex;
}
.home-container12 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-max-width1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-self: center;
  align-items: center;
  flex-shrink: 0;
}
.home-column {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.home-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.home-text10 {
  text-align: left;
}
.home-text11 {
  text-align: left;
}
.home-actions1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.home-button1 {
  text-decoration: none;
}
.home-text12 {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.home-button2 {
  text-decoration: none;
}
.home-text13 {
  color: var(--dl-color-theme-neutral-dark);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.home-video {
  flex: 1;
  width: 550px;
  height: auto;
}
.home-container14 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-fiveunits);
  position: relative;
  grid-template-columns: 1fr 1fr;
}
.home-image-container {
  height: 100%;
  display: flex;
  position: relative;
}
.home-image2 {
  animation-name: fadeIn;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.home-tabs-menu {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-tab-horizontal1 {
  gap: var(--dl-space-space-twounits);
  cursor: pointer;
  display: flex;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.home-divider-container1 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
}
.home-container15 {
  width: 2px;
  align-self: stretch;
  background-color: var(--dl-color-theme-neutral-dark);
}
.home-content2 {
  gap: 16px;
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.home-tab-horizontal2 {
  gap: var(--dl-space-space-twounits);
  cursor: pointer;
  display: flex;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.home-divider-container2 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
}
.home-container16 {
  width: 2px;
  align-self: stretch;
  background-color: var(--dl-color-theme-neutral-dark);
}
.home-content3 {
  gap: 16px;
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.home-tab-horizontal3 {
  gap: var(--dl-space-space-twounits);
  cursor: pointer;
  display: flex;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.home-divider-container3 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
}
.home-container17 {
  width: 2px;
  align-self: stretch;
  background-color: var(--dl-color-theme-neutral-dark);
}
.home-content4 {
  gap: 16px;
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.home-text14 {
  display: inline-block;
}
.home-text15 {
  display: inline-block;
}
.home-text16 {
  display: inline-block;
}
.home-text17 {
  display: inline-block;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text18 {
  display: inline-block;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text19 {
  display: inline-block;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-container18 {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-max-width2 {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-container19 {
  align-items: start;
}
.home-section-header {
  gap: var(--dl-space-space-oneandhalfunits);
  top: 10%;
  display: flex;
  position: sticky;
  align-items: flex-start;
  flex-direction: column;
}
.home-actions2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.home-button3 {
  text-decoration: none;
}
.home-text22 {
  color: var(--dl-color-theme-neutral-dark);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.home-container20 {
  grid-area: span 1/span 1/span 1/span 1;
}
.home-container21 {
  top: 10%;
  position: sticky;
  transform: rotate(-2deg);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-theme-accent1);
}
.home-text24 {
  text-align: left;
}
.home-text25 {
  top: 0px;
  right: 0px;
  margin: auto;
  position: absolute;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}
.home-container22 {
  top: 10%;
  position: sticky;
  transform: rotate(2deg);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: #ecffe8;
}
.home-text27 {
  text-align: left;
}
.home-text28 {
  top: 0px;
  right: var(--dl-space-space-unit);
  position: absolute;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}
.home-container23 {
  top: 10%;
  position: sticky;
  transform: rotate(-2deg);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-theme-accent1);
}
.home-text31 {
  top: 0px;
  right: var(--dl-space-space-unit);
  position: absolute;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}
.home-container24 {
  top: 10%;
  position: sticky;
  transform: rotate(2deg);
  background-color: #ecffe8;
}
.home-text34 {
  top: 0px;
  right: var(--dl-space-space-unit);
  position: absolute;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}
.home-accent2-bg1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(1deg) skew(0deg, 0deg);
  align-self: stretch;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-cardradius);
  justify-content: space-between;
  transform-style: preserve-3d;
  background-color: #C9F8C4;
}
.home-accent2-bg1:hover {
  transform: scale3d(1.1,1.1,1.1);
}
.home-accent1-bg1 {
  width: 100%;
  display: flex;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-2deg) skew(0deg, 0deg);
  align-items: center;
  border-radius: var(--dl-radius-radius-cardradius);
  justify-content: space-between;
  transform-style: preserve-3d;
  background-color: var(--dl-color-theme-accent1);
}
.home-container26 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(1deg) skew(0deg, 0deg);
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-fourunits);
  border-radius: var(--dl-radius-radius-cardradius);
  padding-right: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-sixunits);
}
.home-container26:hover {
  color: var(--dl-color-theme-neutral-light);
  background-color: var(--dl-color-theme-neutral-dark);
}
.home-content5 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-actions3 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.home-button4 {
  animation-name: swing;
  animation-delay: 0s;
  text-decoration: none;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.home-text37 {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.home-max-width4 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-container27 {
  gap: var(--dl-space-space-unit);
  display: flex;
  max-width: 600px;
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-container29 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-image3 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.home-container30 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-container31 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-image4 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.home-container32 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-container33 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-image5 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.home-container34 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-text48 {
  text-align: left;
}
.home-container35 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-image6 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.home-container36 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-container37 {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.home-max-width5 {
  align-self: center;
}
.home-content6 {
  width: 100%;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
}
.home-content7 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.home-content8 {
  width: 100%;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-container38 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-image7 {
  object-fit: cover;
}
.home-text54 {
  font-size: 20px;
}
.home-text55 {
  font-size: 16px;
  text-align: left;
}
.home-container39 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-button5 {
  text-decoration: none;
}
.home-text59 {
  color: var(--dl-color-theme-neutral-dark);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.home-container40 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-image8 {
  object-fit: cover;
}
.home-text60 {
  font-size: 20px;
}
.home-text61 {
  font-size: 16px;
}
.home-container41 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-button6 {
  text-decoration: none;
}
.home-text62 {
  color: var(--dl-color-theme-neutral-dark);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.home-footer7 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.home-max-width6 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-credits {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.home-row {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: space-between;
}
.home-container42 {
  display: flex;
  align-items: flex-start;
}
.home-footer-links {
  gap: 24px;
  display: flex;
  align-items: flex-start;
}
.home-text64 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
}
@media(max-width: 991px) {
  .home-max-width1 {
    flex-direction: column;
  }
  .home-text10 {
    font-size: 30px;
  }
  .home-text11 {
    letter-spacing: inherit;
  }
  .home-actions1 {
    align-self: center;
    justify-content: flex-start;
  }
  .home-button1 {
    border-color: #90ee90;
    border-width: 0px;
  }
  .home-text12 {
    color: #ffffff;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .home-text13 {
    color: var(--dl-color-theme-neutral-dark);
  }
  .home-video {
    width: 100%;
  }
  .home-container14 {
    grid-gap: var(--dl-space-space-twounits);
    grid-template-columns: 1fr;
  }
  .home-image2 {
    height: 100%;
  }
  .home-max-width2 {
    flex-direction: column;
  }
  .home-text22 {
    color: var(--dl-color-theme-neutral-dark);
  }
  .home-text24 {
    text-align: left;
  }
  .home-text26 {
    text-align: left;
  }
  .home-text27 {
    text-align: left;
  }
  .home-text30 {
    text-align: left;
  }
  .home-text33 {
    text-align: center;
  }
  .home-button4 {
    border-color: #90ee90;
    border-width: 0px;
  }
  .home-text37 {
    color: #ffffff;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .home-container27 {
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-content6 {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .home-content8 {
    position: relative;
    align-items: center;
    flex-direction: column;
  }
  .home-text59 {
    color: var(--dl-color-theme-neutral-dark);
  }
  .home-text62 {
    color: var(--dl-color-theme-neutral-dark);
  }
}
@media(max-width: 767px) {
  .home-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-desktop-menu {
    display: none;
  }
  .home-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-text10 {
    font-size: 30px;
  }
  .home-text11 {
    font-size: 16px;
    box-shadow: inherit;
    font-family: Inter;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .home-text12 {
    color: #ffffff;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    background-color: transparent;
  }
  .home-button2 {
    border-width: 0px;
  }
  .home-video {
    height: auto;
  }
  .home-section-header {
    position: static;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-actions2 {
    width: 100%;
    align-self: flex-start;
  }
  .home-button3 {
    border-width: 0px;
  }
  .home-container21 {
    width: 100%;
  }
  .home-container22 {
    width: 100%;
  }
  .home-container23 {
    width: 100%;
  }
  .home-container24 {
    width: 100%;
  }
  .home-container26 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-text37 {
    color: #ffffff;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    background-color: transparent;
  }
  .home-container27 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-card1 {
    width: 100%;
  }
  .home-card2 {
    width: 100%;
  }
  .home-card3 {
    width: 100%;
  }
  .home-card4 {
    width: 100%;
  }
  .home-content6 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-image7 {
    width: 100%;
  }
  .home-button5 {
    border-width: 0px;
  }
  .home-image8 {
    width: 100%;
  }
  .home-button6 {
    border-width: 0px;
  }
  .home-row {
    gap: var(--dl-space-space-oneandhalfunits);
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .home-navbar-interactive {
    padding: var(--dl-space-space-unit);
    padding-right: 16px;
  }
  .home-mobile-menu {
    padding: var(--dl-space-space-unit);
  }
  .home-content1 {
    position: relative;
  }
  .home-text10 {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.1;
  }
  .home-text11 {
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
  }
  .home-actions1 {
    width: 100%;
    flex-direction: row;
    justify-content: center;
  }
  .home-button1 {
    width: auto;
  }
  .home-text12 {
    font-style: normal;
    font-weight: 500;
    background-color: transparent;
  }
  .home-button2 {
    width: auto;
  }
  .home-text13 {
    color: var(--dl-color-theme-neutral-dark);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .home-button3 {
    width: auto;
  }
  .home-text22 {
    color: var(--dl-color-theme-neutral-dark);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .home-actions3 {
    flex-wrap: wrap;
    align-self: stretch;
    justify-content: center;
  }
  .home-button4 {
    width: auto;
  }
  .home-text37 {
    font-style: normal;
    font-weight: 500;
    background-color: transparent;
  }
  .home-button5 {
    width: auto;
  }
  .home-text59 {
    color: var(--dl-color-theme-neutral-dark);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .home-button6 {
    width: auto;
  }
  .home-text62 {
    color: var(--dl-color-theme-neutral-dark);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .home-max-width6 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-footer-links {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}
