.gallery15-gallery3 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.gallery15-max-width {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.gallery15-section-title {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  display: flex;
  max-width: 800px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.gallery15-content {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 900px;
  align-self: center;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.gallery15-container1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.gallery15-image1 {
  width: 100%;
  height: 340px;
  align-self: center;
  border-radius: var(--dl-radius-radius-radius4);
}
.gallery15-image2 {
  width: 100%;
  height: 340px;
  align-self: center;
  border-radius: var(--dl-radius-radius-radius4);
}
.gallery15-container2 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.gallery15-image3 {
  width: 100%;
  height: 340px;
  border-radius: var(--dl-radius-radius-radius4);
}
.gallery15-image4 {
  width: 100%;
  height: 340px;
  border-radius: var(--dl-radius-radius-radius4);
}
.gallery15-text16 {
  display: inline-block;
  text-align: center;
}
.gallery15-text17 {
  display: inline-block;
  margin-bottom: var(--dl-space-space-halfunit);
}
.gallery15-text18 {
  display: inline-block;
  margin-bottom: var(--dl-space-space-halfunit);
}
.gallery15-text19 {
  display: inline-block;
  margin-bottom: var(--dl-space-space-halfunit);
}
.gallery15-text20 {
  display: inline-block;
  margin-bottom: var(--dl-space-space-halfunit);
}
.gallery15-text21 {
  display: inline-block;
  text-align: center;
}
@media(max-width: 991px) {
  .gallery15-content {
    max-width: 600px;
    align-items: center;
    flex-direction: column;
  }
  .gallery15-image1 {
    flex: 0 0 auto;
    width: 100%;
    height: 400px;
  }
  .gallery15-image2 {
    flex: 0 0 auto;
    width: 100%;
    height: 400px;
  }
  .gallery15-image3 {
    flex: 0 0 auto;
    width: 100%;
    height: 400px;
  }
  .gallery15-image4 {
    flex: 0 0 auto;
    width: 100%;
    height: 400px;
  }
}
