.privacy-policy-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    color: #333;
  }
  
  .privacy-policy-title {
    font-size: 2em;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .privacy-policy-last-updated {
    text-align: right;
    font-size: 0.9em;
    color: #555;
  }
  
  .privacy-policy-intro {
    margin-bottom: 20px;
    line-height: 1.6;
  }
  
  .privacy-policy-section-title {
    font-size: 1.5em;
    margin-top: 20px;
  }
  
  .privacy-policy-text {
    margin-bottom: 15px;
    line-height: 1.6;
  }
  
  .privacy-policy-list {
    margin: 10px 0 20px 20px;
    list-style-type: disc;
  }
  
  .privacy-policy-list-item {
    margin-bottom: 10px;
    line-height: 1.6;
  }
  
  .privacy-policy-code {
    background-color: #e8e8e8;
    padding: 2px 4px;
    border-radius: 4px;
  }
  
  .privacy-policy-contact-email {
    color: #007BFF;
    text-decoration: none;
  }
  
  .privacy-policy-contact-email:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 600px) {
    .privacy-policy-container {
      padding: 15px;
    }
  
    .privacy-policy-title {
      font-size: 1.5em;
    }
  
    .privacy-policy-section-title {
      font-size: 1.2em;
    }
  }
  